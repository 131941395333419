<template>
  <CRow>
    <Modal :modal="modal"></Modal>
    <CModal
      :title="modalRemove.title"
      color="danger"
      :centered="true"
      :show.sync="modalRemove.show"
    >
      {{ modalRemove.message }}
      <template #footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="() => (modalRemove.show = false)"
        >
          Cancelar
        </button>
        <button type="button" class="btn btn-danger" @click="handleRemove()">
          Remover
        </button>
      </template>
    </CModal>
    <CCol lg="12">
      <div class="d-flex align-items-center justify-content-end mb-3">
        <CButton class="mr-3" color="danger" @click="showRemoveModal()">
          Excluir Permanentemente
        </CButton>
        <a href="javascript:void(0)" @click="$router.go(-1)">
          <CIcon name="cil-arrow-left" class="mr-2" />Voltar
        </a>
      </div>
      <CForm @submit.prevent="send">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">
              {{ $route.params.id ? 'Newsletter' : 'Cadastrar Newsletter' }}
            </h4>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="4">
                <CInput
                  id="first"
                  v-model="form.createdAtFormated"
                  label="Data de envio"
                  placeholder="Data de envio"
                  required
                  :disabled="true"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  id="first"
                  v-model="form.name"
                  label="Nome"
                  placeholder="Nome"
                  required
                  :disabled="processing"
                />
              </CCol>
              <CCol sm="4">
                <CInput
                  id="first"
                  v-model="form.email"
                  label="Email*"
                  placeholder="Email"
                  required
                  :disabled="processing"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h5 class="mb-0">Dados Restritos</h5>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="3">
                <CInput
                  id="first"
                  v-model="form.ip"
                  label="IP:"
                  placeholder="IP"
                  required
                  :disabled="true"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  id="first"
                  v-model="form.dateAcceptFormated"
                  label="Data aceite dos termos:"
                  placeholder="Data de aceite dos termos"
                  required
                  :disabled="true"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  id="first"
                  v-model="form.browser"
                  label="Navegador"
                  placeholder="Navegador"
                  required
                  :disabled="true"
                />
              </CCol>
              <CCol sm="3">
                <CInput
                  id="first"
                  v-model="form.os"
                  label="Sistema Operacional"
                  placeholder="Sistema Operacional"
                  required
                  :disabled="true"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CRow v-if="$route.params.id">
          <CCol sm="12">
            <CardLog :log="log" />
          </CCol>
        </CRow>
        <div class="d-flex justify-content-end align-items-center mt-4 mb-4">
          <a
            v-if="$route.params.id"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
            >Limpar Alterações</a
          >
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
// import Datepicker from '@/components/ui/Datepicker'
// import FileUploader from '@/components/ui/Fileuploader'
// import FriendlyUrl from '@/components/ui/FriendlyUrl'
// import Multiselect from '@/components/ui/Multiselect'
// import Seo from '@/components/ui/Seo'
// import TextEditor from '@/components/ui/TextEditor'
import { cloneDeep } from 'lodash'
import CardLog from '@/components/ui/CardLog'
import Modal from '@/components/ui/Modal'
import moment from 'moment'
import Service from '@/services/newsletters.service'

export default {
  metaInfo: {
    title: 'Newsletter',
    titleTemplate: 'Manager - OW Interactive - %s'
  },

  components: {
    // Datepicker,
    // FileUploader,
    // FriendlyUrl,
    // Multiselect
    // Seo,
    // TextEditor,
    CardLog,
    Modal
  },

  data() {
    return {
      itens: {
        name: '',
        slug: '',
        email: '',
        status: true
      },
      form: {},
      statusIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      log: {},
      isLoading: true,
      processing: false,
      redirect: true,
      removeItem: null,
      modal: {
        show: false,
        title: '',
        message: '',
        color: ''
      },
      modalRemove: {
        show: false,
        title: '',
        message: '',
        color: ''
      }
    }
  },

  async created() {
    this.setItens()
  },

  methods: {
    async setItens() {
      if (this.$route.params.id) {
        const data = await Service.show(this.$route.params.id)

        if (data) {
          this.itens = {
            ...data
          }

          this.log = {
            created_by: data.created ? data.created.name : 'Seed',
            created_at: moment(data.created_at).format('DD/MM/YYYY HH:mm'),
            updated_by: data.updated ? data.updated.name : null,
            updated_at: moment(data.updated_at).format('DD/MM/YYYY HH:mm')
          }
        }
      }
      this.form = cloneDeep(this.itens)
    },

    resetForm() {
      this.setItens()
    },

    closeModal() {
      this.modal = {
        show: false,
        title: '',
        message: ''
      }
    },

    closeRemoveModal() {
      this.modalRemove = {
        show: false,
        title: '',
        message: ''
      }
    },

    showRemoveModal() {
      this.modalRemove = {
        show: true,
        title: 'Remover Newsletter',
        message:
          'Ao confirmar essa ação a newsletter não poderá ser recuperada, tem certeza que desejar continuar?'
      }
    },

    async forceDelete(id) {
      const response = await Service.forceDelete(id)
      if (response && response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: `a newsletter foi excluída com sucesso.`
        })
        setTimeout(() => {
          this.processing = false
          this.$router.push(
            this.$route.matched[this.$route.matched.length - 2].path
          )
        }, 1000)
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Não é possivel excluir a newsletter'
        })
      }

      this.closeRemoveModal()
    },

    async handleRemove() {
      await this.forceDelete(this.$route.params.id)
    },

    setRedirect(event) {
      this.redirect = !event
    },

    async send() {
      this.processing = true

      let response
      let message

      const data = {
        ...this.form,
        id: this.$route.params.id
      }

      if (this.$route.params.id) {
        response = await Service.update(this.$route.params.id, data)
        message = 'Item foi atualizado com sucesso!'
      } else {
        response = await Service.store(data)
        message = 'Item foi cadastrado com sucesso!'
      }

      this.processing = false

      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          this.resetForm()
          setTimeout(() => {
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.resetForm()
          setTimeout(() => {
            this.processing = false
            document.getElementById('first').focus()
            this.modal.show = false
          }, 1000)
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: `${
            response.data.length > 0
              ? response.data[0].message
              : 'Preencha todos os campos corretamente!'
          }`,
          color: 'danger'
        }
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Ocorreu um erro interno, por favor tente novamente!',
          color: 'danger'
        }
      }
    }
  }
}
</script>
